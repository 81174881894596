<template>
  <calendar v-on:destroyLocalStorage="getEventCount()"></calendar>
</template>

<script>
import Calendar from "./../../../calendar/_components/Calendar/newCalendar.vue";

import Mixins from "@/library/Mixins";
const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
export default {
	mixins: [Mixins],
	components: {
		Calendar,
	},
};
</script>

<style lang="scss">
@import "../../../../assets/scss/_calendar.scss";
</style>
